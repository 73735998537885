import fileManager from "./component/fileManager";
import { loggerViewerLocaleRu } from "@/components/tools/logger-viewer/locales";
import logger from "@/locales/ru-RU/component/logger";

export default {
  // Common
  "component.blank": "Бос",
  "component.format-output-for": "Шығару форматы",
  "component.refresh": "Жаңарту",
  "component.file-already-attached":
    "Файл {label} осы жолға әлдеқашан тіркелген",
  "component.file-not-found": "Файл табылмады",
  "component.to-detach-file": "Файлды тіркеу?",
  "component.to-attach": "Тіркеу",
  "component.rename": "Атын өзгерту",
  "component.file-name": "Файл аты",
  "component.size": "Өлшем",
  "component.directories": "Қалпақтар",
  "component.path": "Жол",
  "component.enter-a-fractional-number": "Ондық санды енгізіңіз",
  "component.confirm-delete": "Жою керек екеніне сенімдісіз бе",
  "component.confirm-delete-with-filler": "Сіз {title} жоюға сенімдісіз бе?",
  "component.to-block": "Блоктау",
  "component.to-unblock": "Блоктауды арттыру",
  "component.read-only": "Только чтение",
  "component.are-you-sure-delete-this-panel":
    "Вы уверены в удалении этой панели?",
  "component.autoheight": "Автовысота",
  "component.click-action": "Действие по клику",
  "component.enable": "Доступно",
  "component.tagSelect.expand": "Expand",
  "component.tagSelect.collapse": "Collapse",
  "component.tagSelect.all": "Барлығы",
  "component.logout": "Шығу",
  "component.save": "Сохранить",
  "component.clear": "Очистить",
  "component.test": "Тест",
  "component.login": "Логин",
  "component.username": "Пайдаланушы аты",
  "component.password": "Пароль",
  "component.create": "Жасау",
  "component.sync": "Синхронизация",
  "component.delete": "Жою",
  "component.move": "Переместить",
  "component.format": "Форматировать",
  "component.upload": "Жүктеу",
  "component.download": "Жүктеу",
  "component.reset": "Сброс",
  "component.internalUrl": "Адрес (внутренний)",
  "component.externalUrl": "Адрес (внешний)",
  "component.finish": "Завершить",
  "component.enabled": "Включена",
  "component.disabled": "Выключена",
  "component.not-available": "Извините, данная страница вам недоступна",
  "component.home": "На Главную",
  "component.on": "Вкл",
  "component.off": "Выкл",
  "component.add": "Қосу",
  "component.edit": "Өңдеу",
  "component.addition": "Қосу",
  "component.updating": "Обновление",
  "component.deleting": "Өшіру",
  "component.renaming": "Атын өзгерту",
  "component.uploading": "Жүктеу",
  "component.downloading": "Жүктеп алу",
  "component.confirm-delete-tool-title": "Вы уверены, что хотите удалить?",
  "component.confirm-delete-menu-title": "Вы уверены, что хотите удалить меню ",
  "component.confirm-delete-menu-group":
    "Вы уверены, что хотите удалить группу ",
  "component.group-has-been-added": "Эта группа уже добавлена!",
  "component.confirm-create-menu-title": "Создать меню?",
  "component.yes": "Иә",
  "component.no": "Жоқ",
  "component.cancel": "Тастау",
  "component.profile": "Профиль",
  "component.label.first-name": "Имя",
  "component.label.last-name": "Фамилия",
  "component.label.email": "E-mail",
  "component.placeholder.first-name": "Пример: Иван",
  "component.placeholder.last-name": "Пример: Иванов",
  "component.placeholder.email": "Пример: login@mail.ru",
  "component.name_filer": "Фильтр по имени",
  "component.type_filer": "Фильтр по типу",
  "component.all": "Барлығы",
  "component.show_disabled": "Отображать недоступные",
  "component.preview": "Предпросмотр",
  "component.name": "Аты",
  "component.description": "Сипаттама",
  "component.label": "Жолаушақ",
  "component.type": "Түрі",
  "component.menu": "Меню",
  "component.folder": "Папка",
  "component.title.settings": "Настройки",
  "component.title.add_tool": "Добавить инструмент",
  "component.theme": "Тема",
  "component.current": "Текущая",
  "component.light": "Светлая",
  "component.medium": "Средняя",
  "component.dark": "Тёмная",
  "component.full": "Полный",
  "component.kiosk": "Киоск",
  "component.kiosk_tv": "Киоск ТВ",
  "component.view": "Вид",
  "component.client": "Клиент",
  "component.client_tooltip": "Клиенские настройки",
  "component.server": "Сервер",
  "component.server_tooltip": "Серверные настройки",
  "component.loading": "Загрузка...",
  "component.version": "Версия",
  "component.default": "По умолчанию",
  "component.title.confirm.share.enabled":
    "Are you sure set property to enable?",
  "component.title.confirm.share.disabled":
    "Are you sure set property to disable?",
  "component.error.tool-unsupported": "Инструмент не поддерживается",
  "component.example": "Пример",
  "component.error": "Ошибка",
  "component.endpoint": "Конечная точка",
  "component.report": "Есеп",
  "component.panel": "Панель",
  "component.dashboard": "Дашборд",
  "component.panel_on_click": "Панель onClick",
  "component.constructor": "Конструктор",
  "component.variables": "Переменные",
  "component.fill": "Заполнить",
  "component.range": "Диапазон",
  "component.select_range": "Выбор диапазона",
  "component.range_left_border": "диапазон.левая граница",
  "component.range_right_border": "диапазон.правая граница",
  "component.visible": "Видимый",
  "component.use_global_property": "Использовать глобальное свойство",
  "component.default_value": "Значение по умолчанию",
  "component.presets": "Предустановки",
  "component.templates": "Шаблоны",
  "component.functions": "Функции",
  "component.Last_5_minutes": "Последние 5 минут",
  "component.Last_15_minutes": "Последние 15 минут",
  "component.Last_30_minutes": "Последние 30 минут",
  "component.Last_1_hour": "Последний час",
  "component.Last_3_hours": "Последние 3 часа",
  "component.Last_6_hours": "Последние 6 часов",
  "component.Last_12_hours": "Последние 12 часов",
  "component.Last_24_hours": "Последние 24 часа",
  "component.Last_2_days": "Последние 2 дня",
  "component.Last_7_days": "Последние 7 дней",
  "component.Last_30_days": "Последние 30 дней",
  "component.Last_90_days": "Последние 90 дней",
  "component.Last_6_months": "Последние 6 месяцев",
  "component.Last_1_year": "Последний год",
  "component.Last_2_years": "Последние 2 года",
  "component.Last_5_years": "Последние 5 лет",
  "component.Current_day": "Текущий день",
  "component.Current_week": "Текущая неделя",
  "component.Current_month": "Текущий месяц",
  "component.Current_quarter": "Текущий квартал",
  "component.Current_year": "Текущий год",
  "component.Yesterday": "Вчера",
  "component.Last_week": "Прошлая неделя",
  "component.Last_month": "Прошлый месяц",
  "component.Last_quarter": "Прошлый квартал",
  "component.Last_year": "Прошлый год",
  "component.event": "Событие",
  "component.events": "События",
  "component.onClick": "Клик",
  "component.action": "Действие",
  "component.sort": "Сортировка",
  "component.service_unavailable": "Сервис недоступен",
  "component.show": "Көрсету",
  "component.hide": "Скрыть",
  "component.attributes": "Атрибуты",
  "component.actions": "Әрекеттер",
  "component.state": "Состояние",
  "component.multiple_values": "Несколько значений",
  "component.include": "Включить",
  "component.select_all": "Выбрать все",
  "component.values_separated_by_comma": "Значения разделенные запятой",
  "component.decorate": "Оформление",
  "component.logo": "Логотип",
  "component.russian": "Орыс",
  "component.english": "Ағылшын",
  "component.kazakh": "Қазақ",
  "component.deutsch": "Неміс",
  "component.style": "Стиль",
  "component.navigation": "Навигация",
  "component.top": "Сверху",
  "component.left": "Слева",
  "component.mix": "Смешанная",
  "component.visibility": "Видимость",
  "component.select-range": "Выбор диапазона",
  "component.absolute": "Абсолютное",
  "component.relative": "Относительное",
  "component.token": "Токен",
  "component.from": "из",
  "component.change-value": "Изменить значение",
  "component.applicationName": "Имя приложения",
  "component.source": "Источник",
  "component.values": "Значения",
  "component.multi-values": "Бірнеше мәндер",
  "component.include-select-all": "Өшіру [Барлығы]",
  "component.query": "Запрос",
  "component.custom": "Пользовательский",
  "component.value": "Значение",
  "component.constant": "Константа",
  "component.link": "Связная",
  "component.type-value": "Тип переменной",
  "component.input-variable-name": "Задайте имя переменной!",
  "component.input-rule-name": "Задайте имя правила!",
  "component.name-already-exists": "Запись с таким именем уже существует!",
  "component.date-output-format": "Формат вывода",
  "component.date-output-accuracy": "Точность ввода",
  "component.second": "секунда",
  "component.minute": "минута",
  "component.hour": "час",
  "component.day": "день",
  "component.week": "неделя",
  "component.month": "месяц",
  "component.quarter": "квартал",
  "component.year": "год",
  "component.invalid-variable-value": "Не верное значение переменной",
  "component.change": "Изменить",
  "component.unpin": "Тіркемен тастау",
  "component.removed": "Удален",
  "component.not-removed": "Не удален",
  "component.added": "Добавлен",
  "component.not-added": "Не добавлен",
  "component.changed": "Өзгертілді",
  "component.not-changed": "Не изменен",
  "component.user-created": "пользователь, который добавил",
  "component.user-updated": "пользователь, который обновил",
  "component.date-updated": "дата обновления",
  "component.date-created": "дата добавления",
  "component.conditional-formatting": "Условное форматирование",
  "component.rule": "Правило",
  "component.cell": "Ячейки",
  "component.please-select": "Выберите из списка",
  "component.formatter": "Форматер",
  "component.font-color": "Цвет шрифта",
  "component.background-color": "Цвет фона",
  "component.font": "Шрифт",
  "component.font-size": "Размер шрифта",
  "component.condition": "Шарт",
  "component.localization.support": "Поддержка локализации",
  "component.not-path-for-panel": "Не указан путь для панели",
  "component.access": "Доступ",
  "component.info": "Инфо",
  "component.system-info": "Системная информация",
  "component.width": "Ширина",
  "component.empty": "пустое",
  "component.file": "Файл",
  "component.not-found": "не найден",
  "component.clone": "Клонировать",
  "component.row-number": "Номер строки",
  "component.invalid-value": "Введено неверное значение",

  // Grafana
  "component.grafana.internal": "Адрес сервера(внутренний)",
  "component.grafana.external": "Внешняя ссылка",
  "component.grafana.basic-api-uri": "Basic api uri",
  "component.grafana.api-key": "Api key",
  "component.footer.allRightReserved": "Барлық құқықтар қорғалған",
  "component.roles": "Роли",
  "component.groups": "Группы",
  "component.tools": "Инструменты",
  "component.grafana.remove-panel": "Удалить панель",
  "component.grafana.columns-count": "Столбцы",
  "component.grafana.row-height": "Высота строк",
  "component.grafana.user-transformation": "Изменять панели во вьювере",
  "component.grafana.stretch-to-window-height": "Растянуть на высоту окна",
  "component.grafana.edit-panel": "Редактировать панель",
  "component.grafana.panel-group": "Группа панелей",
  "component.grafana.use-board": "Использовать доску",
  "component.grafana.system-info-error":
    "Не удалось получить информацию по сервису",
  "component.grafana.add-panel": "Добавить",

  // System db
  "component.system-db.hostname": "хост",
  "component.system-db.port": "порт",
  "component.system-db.name": "имя базы данных",
  "component.system-db.username": "имя пользователя",
  "component.system-db.driver": "драйвер",
  "component.system-db.want-to-delete":
    "Эта операция необратима. Вы уверены, что хотите удалить системную базу данных?",
  "component.system-db.want-to-format":
    "Эта операция необратима. Вы уверены, что хотите форматировать системную базу данных?",

  // Sso
  "component.sso.realm": "Область",
  "component.sso.clientId": "ID клиента",
  "component.sso.port": "Порт",
  "component.sso.secret": "Секрет",
  "component.sso.system-info-error":
    "Не удалось получить информацию по сервису",

  //Rs
  "component.rs.system-info-error": "Не удалось получить информацию по сервису",

  // User-profile
  "component.profile.login-name": "Имя входа",
  "component.profile.new-password": "Новый пароль",
  "component.profile.new-password-repeat": "Новый пароль [повтор]",
  "component.profile.change-password": "Сменить пароль",

  // Hasura
  "component.hasura.service-address": "Адрес сервиса",
  "component.hasura.server": "Сервер",
  "component.hasura.server-settings": "Настройки сервера",
  "component.hasura.select.label": "Таблица",
  "component.hasura.select.placeholder": "Выберите таблицу",
  "component.hasura.fields": "Поля",
  "component.hasura.table.label": "Ярлык",
  "component.hasura.table.type": "Тип",
  "component.hasura.table.attributes": "Атрибуты",
  "component.hasura.table.action": "Действие",
  "component.hasura.field": "Поле",
  "component.hasura.no-description": "Нет описания",
  "component.hasura.fields-not-configured": "Не настроены поля",
  "component.hasura.form-constructor": "Конструктор формы ввода/вывода",
  "component.hasura.min-height": "Мин. высота",
  "component.hasura.min-width": "Мин. ширина",
  "component.hasura.max-height": "Макс. высота",
  "component.hasura.max-width": "Макс. ширина",
  "component.hasura.columns-quantity": "Кол-во столбцов",
  "component.hasura.missing-primary-key": "Отсутствует первичный ключ",
  "component.hasura.table-settings": "Настройки таблицы",
  "component.hasura.decorate": "Оформление",
  "component.hasura.fitAndFill": "Строки на всю ширину таблицы",
  "component.hasura.rowHeight": "Высота строки",
  "component.hasura.fix": "Фиксатор",
  "component.hasura.rowsTop": "Сверху",
  "component.hasura.colsLeft": "Слева",
  "component.hasura.colsRight": "Справа",
  "component.hasura.export": "Экспорт",
  "component.hasura.import": "Импорт",
  "component.hasura.output": "Вывод",
  "component.hasura.relation": "Отношение",
  "component.hasura.restrictions": "Ограничения",
  "component.hasura.content-type": "Тип контента",
  "component.hasura.no-value": "Нет значения",
  "component.hasura.source": "Источник",
  "component.hasura.matrix": "Матрица",
  "component.hasura.axisY": "Ось Y",
  "component.hasura.axisX": "Ось X",
  "component.hasura.matrix-field": "Поле матрицы",
  "component.hasura.nesting-level": "Уровень вложенности",
  "component.hasura.pagination": "Пагинация",
  "component.hasura.rows-per-page": "строк/стр",
  "component.hasura.conditions": "Условия",
  "component.hasura.sorting-type": "Тип сортировки",
  "component.hasura.soft": "Мягкое",
  "component.hasura.invalid-json-format": "Невалидный json-формат",
  "component.hasura.invalid-xml-format": "Невалидный xml-формат",
  "component.hasura.adding-group": "Добавление группы",
  "component.hasura.changing-group": "Изменение группы",
  "component.hasura.permissions": "Права",

  // Global
  "component.global.name-restriction": "Текст не более 50 символов",
  "component.global.desc-restriction": "Текст не более 255 символов",
  "component.global.language-selection": "Язык",
  "component.global.default-value": "Арнайы мәні",
  "component.global.languages": "Список",

  // AuthTools
  "component.auth.users": "Пользователи",
  "component.auth.disabled-users": "Заблокированные пользователи ",
  "component.auth.groups": "Группы",
  "component.auth.disabled-tools": "Заблокированные инструменты",
  "component.auth.disabled": "Недоступен",
  "component.auth.enabled": "Доступен",
  "component.auth.inherited": "Унаследовано",
  "component.auth.default": "По умолчанию",
  "component.auth.name-filter": "Фильтр по именам",
  "component.auth.tools-filter": "Фильтр по инструментам",

  // Navigation
  "component.navigation.change-directory": "Изменить директорию",
  "component.navigation.change-tool": "Изменить инструмент",

  // Crud
  "component.function.pagination-top": "Верх",
  "component.function.pagination-bottom": "Низ",
  "component.ui.CrudFormConstructor.tooltipColumnsQuantity":
    "Сначала освободите занятое элементами место, а потом уменьшайте значение",

  // report
  "component.report.path-not-set": "Не задан путь для отчета!",
  "component.report.time-functions": "Функции времени",

  // DataInput
  "component.data-input.choose": "выбрать",
  "component.data-input.download": "Жүктеу",
  "component.data-input.upload": "загрузить",
  "component.data-input.new-file": "Жаңа файл",
  "component.data-input.date-of-download": "дата загрузки",
  "component.data-input.name-of-uploaded-file": "Имя загруженного файла",
  "component.data-input.no-file-selected": "Не выбран файл",
  "component.data-input.delete-the-selected-file": "Удалить выбранный файл?",
  "component.data-input.enter-file-name": "Введите название файла",

  // DataInputs placeholders
  "component.data.input.int": "Пример: 123",
  "component.data.input.bigint": "Пример: 123",
  "component.data.input.string": "Пример: Символы",
  "component.data.input.xml": "Пример: Символы",
  "component.data.input.float8": "Пример: 123.321",
  "component.data.input._float8": "Пример: 123.321",
  "component.data.input._int4": "Пример: 123",
  "component.data.input.timestamp": "Күн мен уақытты таңдаңыз",
  "component.data.input.timestamptz": "Выберите дату и время",
  "component.data.input.date": "Күнді таңдаңыз",
  "component.data.input.bpchar": "Мәтін енгізіңіз",
  "component.data.input.object": "Тізімнен таңдаңыз",
  "component.data.input.time": "Уақытты таңдаңыз",
  "component.data.input._varchar": "Пример: абв",
  "component.data.input.uuid": "UUID енгізіңіз",
  "component.data.input.json":
    'Пример: { "fileName": "my file name", "fileSize": 1024 }',
  "component.data.input.jsonb":
    'Пример: { "fileName": "my file name", "fileSize": 1024 }',
  "component.data.input.file-selection": "Выбор файла",

  // ArrayEditor
  "component.array-editor.delete-the-selected-row":
    "Сіз бұл жолды өшіруге келісесіз бе?",

  // IOCoordinates
  "component.io-coordinates.map": "Карта",

  // IOJsonbSys
  "component.io-jsonb-sys.history": "История",
  "component.io-jsonb-sys.user": "Пользователь",
  "component.io-jsonb-sys.timestamp": "Метка времени",
  "component.io-jsonb-sys.adding": "Добавление",
  "component.io-jsonb-sys.editing": "Редактирование",
  "component.io-jsonb-sys.deleting": "Удаление",

  // ActionButton
  "action.button-create": "Қосу",
  "action.button-save": "Сақтау",
  "action.button-cancel": "Болдырмау",
  "action.button-delete": "Жою",
  "action.button-clone": "Клондау",
  "action.button-import": "Импорт",
  "action.button-export": "Экспорт",
  "action.button-download": "Жүктеу",
  "action.button-upload": "Жүктеу",
  "action.button-reset": "Қайта орнату",
  "action.button-play": "Бастау",
  "action.button-login": "Кіру",
  "action.button-fill": "Толтыру",
  "action.button-update": "Жаңарту",
  "action.button-edit": "Өңдеу",
  "action.button-remove": "Қою",

  // FieldFilter
  "modal.field-filter-modal.filter": "Фильтр",
  "component.field-filter.filter": "Сүзгі",
  "component.field-filter.type": "Түр",
  "component.field-filter.off": "Өшірілген",
  "component.field-filter.eq": "Тең",
  "component.field-filter.neq": "Тең емес",
  "component.field-filter.gt": "Көбірек",
  "component.field-filter.gte": "Көбірек немесе тең",
  "component.field-filter.in": "Тізімге енгізілген",
  "component.field-filter.nin": "Тізімге енгізілмеген",
  "component.field-filter.is_null": "Бос немесе жоқ",
  "component.field-filter.lt": "Азырақ",
  "component.field-filter.lte": "Аз немесе тең",
  "component.field-filter.like":
    "Ұқсас (мазмұны бойынша, регистрді ескере отырып)",
  "component.field-filter.nlike":
    "Ұқсас емес (мазмұны бойынша, регистрді ескере отырып)",
  "component.field-filter.ilike":
    "Ұқсас (мазмұны бойынша, регистрді ескермейді)",
  "component.field-filter.nilike":
    "Ұқсас емес (мазмұны бойынша, регистрді ескермейді)",
  "component.field-filter.similar": "Ұқсас (ұқсастық бойынша)",
  "component.field-filter.nsimilar": "Ұқсас емес (ұқсас)",
  "component.field-filter.regex": "Тұрақты өрнек (регистрді ескереді)",
  "component.field-filter.nregex": "Тұрақты өрнек емес (әріпті ескереді)",
  "component.field-filter.iregex": "Тұрақты өрнек (регистрді ескермейді)",
  "component.field-filter.niregex": "Тұрақты өрнек емес (регистрді ескермейді)",
  "component.field-filter.on": "Қосылған",
  "component.field-filter.value": "Мән",

  // editor/application
  "page.settings-application.general": "Общие",
  "page.settings-application.text": "Текст",
  "page.settings-application.logoMesone": "Логотип MESone",
  "page.settings-application.footer": "Футер",

  // User/profile
  "page.user-profile.user-name": "Имя пользователя",
  "page.user-profile.admin": "Администратор",
  "page.user-profile.first-name": "Имя",
  "page.user-profile.placeholder-name": "Иван",
  "page.user-profile.last-name": "Фамилия",
  "page.user-profile.default-tool": "Инструмент по умолчанию",
  "page.user-profile.tools": "Инструменты",
  "page.user-profile.placeholder-input-search": "Введите текст для поиска",
  "page.user-profile.groups": "Топтар",

  // Instance editor
  "component.instance-editor.image": "Изображение",
  "component.instance-editor.text": "Текст",
  "component.instance-editor.main": "Основной",
  "component.instance-editor.additional": "Дополнительный",

  // Switcher
  "component.switcher.enabled": "Қосылған",

  // Select users groups
  "select-users-groups.users": "Пайдаланушылар",

  // Tools navigator
  "component.tools-navigator.showTypes": "Түрлерді көрсету",
  "component.tools-navigator.showBlocked": "Қол жетімсіздіктерді көрсету",

  // Tool settings common
  "component.tool-settings-common.permissions": "Рұқсаттар",
  "component.tool-settings-common.name": "Атауы",
  "component.tool-settings-common.type": "Түрі",
  "component.tool-settings-common.label": "Жолаушақ",
  "component.tool-settings-common.description": "Сипаттама",
  "component.tool-settings-common.info": "Ақпарат",
  "component.tool-settings-common.sureDelete":
    "Сіз {name} құралын жойғыңыз келеді ме?",

  // Table settings
  "component.table-settings.pagination": "Бетбелгілеу",
  "component.table-settings.rows": "жол/бет",
  "component.table-settings.top": "Жоғарысу",
  "component.table-settings.bottom": "Төмендеу",
  "component.table-settings.fillAndFit": "Барлық кеңістіктерді тарату",
  "component.table-settings.rowHeight": "Жол биіктігі",
  "component.table-settings.fontSize": "Қаріп өлшемі",
  "component.table-settings.fix": "Бекітіп алу",
  "component.table-settings.left": "Сол жақ",
  "component.table-settings.right": "Оң жақ",
  "component.table-settings.export": "Шығару",
  "component.table-settings.import": "Жүктеу",

  // Range editor
  "component.range-editor.range": "Диапазон",
  "component.range-editor.visibility": "Көріністік",
  "component.range-editor.useGlobalProperty": "Глобальды мәнді қолдану",
  "component.range-editor.presets": "Белгілі айрықтамалар",
  "component.range-editor.defaultValue": "Әдепкі мән",
  "component.range-editor.absolute": "Абсолюттік",
  "component.range-editor.relative": "Нисбетті",

  // Range viewer
  "component.range-preset.Last_5_minutes": "соңғы 5 мин.",
  "component.range-preset.Last_15_minutes": "соңғы 15 мин.",
  "component.range-preset.Last_30_minutes": "соңғы 30 мин.",
  "component.range-preset.Last_1_hour": "соңғы сағ.",
  "component.range-preset.Last_3_hours": "соңғы 3 сағ.",
  "component.range-preset.Last_6_hours": "соңғы 6 сағ.",
  "component.range-preset.Last_12_hours": "соңғы күннің үшінші бөл.",
  "component.range-preset.Last_24_hours": "соңғы күн",
  "component.range-preset.Last_2_days": "соңғы 2 күн",
  "component.range-preset.Last_7_days": "соңғы апта",
  "component.range-preset.Last_30_days": "соңғы ай",
  "component.range-preset.Last_90_days": "соңғы тоқсан",
  "component.range-preset.Last_6_months": "соңғы жарты жыл",
  "component.range-preset.Last_1_year": "соңғы жыл",
  "component.range-preset.Last_2_years": "соңғы 2 жыл",
  "component.range-preset.Last_5_years": "соңғы 5 жыл",
  "component.range-preset.Current_day": "ағымдағы күн",
  "component.range-preset.Current_week": "ағымдағы апта",
  "component.range-preset.Current_month": "ағымдағы ай",
  "component.range-preset.Current_quarter": "ағымдағы тоқсан",
  "component.range-preset.Current_year": "ағымдағы жыл",
  "component.range-preset.Yesterday": "кеше",
  "component.range-preset.Last_week": "соңғы апта",
  "component.range-preset.Last_month": "соңғы ай",
  "component.range-preset.Last_quarter": "соңғы тоқсан",
  "component.range-preset.Last_year": "соңғы жыл",

  // Updater editor
  "component.updater-editor.range": "Жаңарту",
  "component.updater-editor.visibility": "Көріністік",
  "component.updater-editor.useGlobalValue": "Глобальды мәнді қолдану",
  "component.updater-editor.defaultValue": "Әдепкі мән",

  "component.updater-preset.Off": "Жою",
  "component.updater-preset.5s": "5 сек.",
  "component.updater-preset.10s": "10 сек.",
  "component.updater-preset.30s": "30 сек.",
  "component.updater-preset.1m": "1 мин.",
  "component.updater-preset.5m": "5 мин.",
  "component.updater-preset.15m": "15 мин.",
  "component.updater-preset.30m": "30 мин.",
  "component.updater-preset.1h": "1 сағ.",
  "component.updater-preset.2h": "2 сағ.",
  "component.updater-preset.1d": "1 күн",

  // List extended
  "component.list-extended.selectAll": "Барлығын таңдау",
  "component.list-extended.all": "Барлығы",

  // Data input int
  "component.data-input-int.placeholder": "Бүтін санды енгізіңіз",

  // Data input settings
  "component.data-input-settings.contentType": "Контенттің түрі",
  "component.data-input-settings.systemInformation": "Жүйе туралы ақпарат",
  "component.data-input-settings.dateInputFormat": "Күнді енгізу форматы",
  "component.data-input-settings.timeInputFormat": "Уақытты енгізу форматы",
  "component.data-input-settings.dateTimeInputFormat":
    "Күн мен уақытты енгізу форматы",
  "component.data-input-settings.editor": "Редактор",
  "component.data-input-settings.files": "Файлдар",
  "component.data-input-settings.coordinates": "Координаттар",
  "component.data-input-settings.week": "Апта",
  "component.data-input-settings.month": "Ай",
  "component.data-input-settings.quarter": "Тоқсан",
  "component.data-input-settings.year": "Жыл",
  "component.data-input-jpg-base64.upload": "Жүктеу",

  // Variable custom editor
  "component.variable.custom.editor": "Мағынаның жиынтығы",
  "component.variable-custom-editor.preview-default-value":
    "Алдын ала қарау/Әдепкі мән",

  // Variable value editor
  "component.variable-value-editor.dataType": "Деректер түрі",
  "component.variable-value-editor.boolean": "Мәндік",
  "component.variable-value-editor.int": "Бүтін сан",
  "component.variable-value-editor.float4": "Оныңдыл сан",
  "component.variable-value-editor.float": "Оныңдыл сан",
  "component.variable-value-editor.bpchar": "Символды тізбек",
  "component.variable-value-editor.date": "Күн",
  "component.variable-value-editor.time": "Уақыт",
  "component.variable-value-editor.timestamp": "Уақыт белгісі",
  "component.variable-value-editor.bool": "Мәндік мәсім",
  "component.variable-value-editor.int4": "Бүтін санды мәсім",
  "component.variable-value-editor.float8": "Оныңдыл санды мәсім",
  "component.variable-value-editor.varchar": "Символды тізбектер мәсім",
  "component.variable-value-editor.uuid": "Анықтама",

  // Variable query editor
  "component.list-extended.templates": "Үлгілер",
  "component.list-extended.basic": "Негізгі",
  "component.list-extended.textValueAliases": "Мәтін/Мәндік атаулары",
  "component.list-extended.variables": "Өзгерулер",
  "component.list-extended.query": "Сұрау",
  "component.list-extended.previewDefaultValue": "Алдын ала қарау/Әдепкі мән",

  // Row control
  "component.row-control.edit": "Өзгерту",
  "component.row-control.move": "Жылжыту",
  "component.row-control.delete": "Жою",

  // Row mover
  "component.row-mover.move": "Жылжыту",
  "component.row-mover.rowNumber": "Жолдың нөмірі",

  // Variables editor
  "component.variables-editor.name": "Аты",
  "component.variables-editor.label": "Жапсырма",
  "component.variables-editor.type": "Тип",
  "component.variables-editor.attributes": "Атрибуттар",
  "component.variables-editor.actions": "Әрекеттер",
  "component.variables-editor.confirmDelete":
    "{name} өзгерушін жою келесін бе?",
  "component.variables-editor.var-value": "Өзгеру",
  "component.variables-editor.var-custom": "Статикалы мәтінбек",
  "component.variables-editor.var-query": "Динамикалы мәтінбек",
  "component.variables-editor.dataTransfer": "Деректерді тасымалдау",
  "component.variables-editor.Auto": "Авто",
  "component.variables-editor.Manual": "Қолмен",

  // Variable editor
  "component.variable-editor.add": "Қосу",
  "component.variable-editor.edit": "Өңдеу",
  "component.variable-editor.readOnly": "Тек оқу",
  "component.variable-editor.visible": "Көрінетін",
  "component.variable-editor.name": "Аты",
  "component.variable-editor.enterVariableName": "Өзгеру атын енгізіңіз",
  "component.variable-editor.label": "Жапсырма",
  "component.variable-editor.description": "Сипаттама",
  "component.variable-editor.type": "Тип",
  "component.variable-editor.width": "Ені",
  "component.variable-editor.invalidName": "Жарамсыз аты",

  // Table fields
  "component.table-fields.name": "Аты",
  "component.table-fields.label": "Жапсырма",
  "component.table-fields.type": "Тип",
  "component.table-fields.attributes": "Атрибуттар",
  "component.table-fields.actions": "Әрекеттер",
  "component.table-fields.totalItems": "Жалпы {i} элементтер",

  // Table form editor
  "component.table-form-editor.preview": "Алдын ала қарау",
  "component.table-form-editor.min": "Мин.",
  "component.table-form-editor.max": "Макс.",
  "component.table-form-editor.minHeight": "Мин. биіктік",
  "component.table-form-editor.maxHeight": "Макс. биіктік",
  "component.table-form-editor.width": "Ені",
  "component.table-form-editor.minWidth": "Мин. ені",
  "component.table-form-editor.maxWidth": "Макс. ені",
  "component.table-form-editor.columns": "Бағандар",
  "component.table-form-editor.minimumReached":
    "Минимумға жеттіңіз. Әрі бірінші орын азайту керек, онда бағандар санын өзгертіңіз.",

  // Field sorting editor
  "modal.field-sorting-editor.edit": "Өзгерту",
  "modal.field-sorting-editor.field": "Кесте",
  "modal.field-sorting-editor.sortingRule": "Сұрыптау тегі",
  "modal.field-sorting-editor.noSorting": "Сұрыптау жоқ",
  "modal.field-sorting-editor.asc": "Артуы бойынша",
  "modal.field-sorting-editor.asc_nulls_first":
    "Артуы бойынша, бастауларда нөл",
  "modal.field-sorting-editor.asc_nulls_last": "Артуы бойынша, соңғыларда нөл",
  "modal.field-sorting-editor.desc": "Кемуі бойынша",
  "modal.field-sorting-editor.desc_nulls_first":
    "Кемуі бойынша, бастауларда нөл",
  "modal.field-sorting-editor.desc_nulls_last": "Кемуі бойынша, соңғыларда нөл",

  // Conditional formatting
  "component.conditional-formatting.confirmDelete":
    "Сіз {name} ережесін жою келесіз бе?",
  "component.conditional-formatting.name": "Аты",
  "component.conditional-formatting.description": "Сипаттама",
  "component.conditional-formatting.attributes": "Атрибуттар",
  "component.conditional-formatting.actions": "Әрекеттер",
  "component.conditional-formatting.totalItems": "Жалпы {i} элементтер",
  "component.conditional-formatting.layer": "Қабат",

  // Conditional Formatting Editor
  "component.conditional-format-editor.add": "Қосу",
  "component.conditional-format-editor.edit": "Өзгерту",
  "component.conditional-format-editor.name": "Аты",
  "component.conditional-format-editor.enterRuleName": "Тегінің атын енгізіңіз",
  "component.conditional-format-editor.description": "Сипаттама",
  "component.conditional-format-editor.fields": "Кестелер",
  "component.conditional-format-editor.allFields":
    "Барлық кестелер (бұрынның жолы)",
  "component.conditional-format-editor.formatter": "Форматтаушы",
  "component.conditional-format-editor.fontColor": "Қаріптің түсі",
  "component.conditional-format-editor.backgroundColor": "Фоның түсі",
  "component.conditional-format-editor.font": "Қаріп",
  "component.conditional-format-editor.bold": "Тығыз",
  "component.conditional-format-editor.italic": "Курсив",
  "component.conditional-format-editor.underline": "Сызу",
  "component.conditional-format-editor.conditions": "Шарттар",

  // Table source
  "component.table-source.table": "Кесте",
  "component.table-source.selectTable": "Кестені таңдау",
  "component.table-source.description": "Сипаттама",
  "component.table-source.options": "Опция",
  "component.table-source.main": "Негізгі",
  "component.table-source.aggregated": "Агрегатталған",
  "component.table-source.withKeys": "Кілттермен",

  // Tools editor
  "component.tools-editor.source": "Көзірек",
  "component.tools-editor.permissions": "Рұқсаттар",
  "component.tools-editor.fields": "Кестелер",
  "component.tools-editor.groups": "Топтар",
  "component.tools-editor.tableSettings": "Кесте баптаулары",
  "component.tools-editor.preview": "Алдын ала қарау",
  "component.tools-editor.formBuilder": "Кіру/Шығу формасын құрастырушы",
  "component.tools-editor.timeFunctions": "Уақыт функциялары",
  "component.tools-editor.variables": "Өзгерістер",
  "component.tools-editor.conditions": "Шарттар",
  "component.tools-editor.sorting": "Сұрыптау",
  "component.tools-editor.conditionalFormatting": "Шартты форматтау",
  "component.tools-editor.constructor": "Конструктор",
  "component.tools-editor.matrix": "Матрица",
  "component.tools-editor.additionalFormats": "Қосымша форматтар",
  "component.tools-editor.localizationSupport": "Локализацияның қолдауы",
  "component.tools-editor.args": "Аргументтер",

  // Table permissions
  "component.table-permissions.add": "Қосу",
  "component.table-permissions.update": "Жаңарту",
  "component.table-permissions.delete": "Жою",

  // Dashboard editor
  "component.dashboard-editor.add": "Қосу",
  "component.dashboard-editor.edit": "Өңдеу",
  "component.dashboard-editor.name": "Аты",
  "component.dashboard-editor.selectPanelName": "Панель атын таңдаңыз",
  "component.dashboard-editor.localizationSupport": "Локализацияның қолдауы",
  "component.dashboard-editor.view": "Көрініс",
  "component.dashboard-editor.full": "Толық",
  "component.dashboard-editor.kiosk": "Киоск",
  "component.dashboard-editor.kioskTV": "Киоск ТВ",
  "component.dashboard-editor.theme": "Тақырып",
  "component.dashboard-editor.current": "Ағымдағы",
  "component.dashboard-editor.light": "Жарық",
  "component.dashboard-editor.dark": "Көк",
  "component.dashboard-editor.openToolOnClick": "Құжатты басу арқылы ашу",
  "component.dashboard-editor.selectTool": "Құжатты таңдау",

  // Dashboard designer
  "component.dashboard-designer.autoHeight": "Автокөрсету",
  "component.dashboard-designer.changePanelsInViewMode":
    "Көру режимінде панелдерді өзгерту",
  "component.dashboard-designer.rowHeight": "Жол көрсету",
  "component.dashboard-designer.columns": "Бағандар",

  // Dashobard panel
  "component.dashboard-panel.edit": "Өзгерту",
  "component.dashboard-panel.delete": "Жою",
  "component.dashboard-panel.confirmationMessage":
    "{i} панельді өшіргіңіз келеді ме?",

  // Settings users
  "pages.settings-users.staticInformation": "Статикалы ақпарат",
  "pages.settings-users.created": "Жасалды",
  "pages.settings-users.userName": "Пайдаланушы аты",
  "pages.settings-users.editableInformation": "Өзгертілетін ақпарат",
  "pages.settings-users.name": "Аты",
  "pages.settings-users.surname": "Тегі",
  "pages.settings-users.changePassword": "Құпия сөзді өзгерту",
  "pages.settings-users.newPassword": "Жаңа құпия сөз",
  "pages.settings-users.enterNewPasswordAgain": "Жаңа құпия сөзді қайталаңыз",
  "pages.settings-users.groups": "Топтар",
  "pages.settings-users.tools": "Құралдар",
  "pages.settings-users.confirmDelete":
    "Сіз {i} пайдаланушысын өшірмек келесіз бе?",

  // Settings groups
  "pages.settings-groups.confirmDelete": "Сіз {i} топты жоюға сенімдісіз бе?",
  "pages.settings-groups.users": "Пайдаланушылар",
  "pages.settings-groups.subGroup": "Топтау",

  // Settings services grafana
  "pages.settings-grafana-services.version": "Нұсқа",
  "pages.settings-grafana-services.executionTime": "Орындау уақыты",
  "pages.settings-grafana-services.description": "Мәліметі",
  "pages.settings-grafana-services.error": "Қате",
  "pages.settings-grafana-services.waiting": "Күту",

  // Field editor simple
  "component.field-editor-simple.edit": "Өзгерту",
  "component.field-editor-simple.name": "Аты",
  "component.field-editor-simple.type": "Түрі",
  "component.field-editor-simple.description": "Сипаттама",
  "component.field-editor-simple.label": "Таңбалау",
  "component.field-editor-simple.parameters": "Параметрлер",
  "component.field-editor-simple.keyField": "Кілті баған",
  "component.field-editor-simple.readOnly": "Тек оқу",
  "component.field-editor-simple.lazyLoading": "Lazy жүктеу",
  "component.field-editor-simple.quickView": "Тез қарау",
  "component.field-editor-simple.quickEdit": "Тез редакциялау",
  "component.field-editor-simple.sorting": "Сортау",
  "component.field-editor-simple.filter": "Фильтр",
  "component.field-editor-simple.foreignKey": "Сыртқы кілт",
  "component.field-editor-simple.selectForeignKey": "Сыртқы кілті таңдаңыз",
  "component.field-editor-simple.formBuilder":
    "Тез қарау / редакциялау формасы жасаушысы",
  "component.field-editor-simple.inputOutputSettings":
    "Кіру/шығару параметрлері",
  "component.field-editor-simple.outputFormat": "Шығару пішімі",
  "component.field-editor-simple.quickAction": "Тез әрекет",
  "component.field-editor-simple.disabled": "Ажыратылған",
  "component.field-editor-simple.view": "Көру",
  "component.field-editor-simple.editing": "Түзету",

  // Field editor object
  "component.field-editor-object.edit": "Өзгерту",
  "component.field-editor-object.name": "Аты",
  "component.field-editor-object.type": "Түрі",
  "component.field-editor-object.description": "Сипаттама",
  "component.field-editor-object.label": "Таңбалау",
  "component.field-editor-object.parameters": "Параметрлер",
  "component.field-editor-object.readOnly": "Тек оқу",
  "component.field-editor-object.filter": "Фильтр",
  "component.field-editor-object.complexAddition": "Комплексті қосу",
  "component.field-editor-object.groupFields": "Бағандарды топтау",
  "component.field-editor-object.complexAdditionFormBuilder":
    "Комплексті қосу формасының жасаушысы",
  "component.field-editor-object.conditions": "Шарттар",
  "component.field-editor-object.sorting": "Сортау",
  "component.field-editor-object.pkeyConstraint": "Шектеу pk",

  // Field editor list
  "component.field-editor-list.edit": "Өзгерту",
  "component.field-editor-list.name": "Аты",
  "component.field-editor-list.type": "Түрі",
  "component.field-editor-list.description": "Сипаттама",
  "component.field-editor-list.label": "Таңбалау",
  "component.field-editor-list.parameters": "Параметрлер",
  "component.field-editor-list.readOnly": "Тек оқу",
  "component.field-editor-list.filter": "Фильтр",
  "component.field-editor-list.complexAddition": "Комплексті қосу",
  "component.field-editor-list.viewerFormBuilder": "Пiшiн формасының жасаушысы",
  "component.field-editor-list.complexAdditionFormBuilder":
    "Комплексті қосу формасының жасаушысы",
  "component.field-editor-list.conditions": "Шарттар",
  "component.field-editor-list.sorting": "Сортау",

  // User creator
  "component.user-creator.add": "Қосу",
  "component.user-creator.user-name": "Пайдаланушы аты",
  "component.user-creator.enter-name": "Атын енгізіңіз",
  "component.user-creator.invalidName": "Жарамсыз аты",

  // Group creator
  "component.group-creator.add": "Қосу",
  "component.group-creator.name": "Аты",
  "component.group-creator.enterName": "Атын енгізіңіз",
  "component.group-creator.invalidName": "Жарамсыз аты",

  // Table editor
  "component.table-editor.add": "Жасау",
  "component.table-editor.edit": "Өңдеу",
  "component.table-editor.delete": "Жою",

  // table/viewer
  "component.table-viewer.total": "Жалпы элементтер",
  "component.table-viewer.sort": "Сұрыптау",
  "component.table-viewer.noSort": "Сұрыптау жоқ",
  "component.table-viewer.ascending": "Артуы бойынша",
  "component.table-viewer.ascendingEmptyBeginning":
    "Артуы бойынша (басында бос)",
  "component.table-viewer.ascendingEmptyEnd": "Артуы бойынша (соңында бос)",
  "component.table-viewer.descending": "Кемуі бойынша",
  "component.table-viewer.descendingEmptyBeginning":
    "Кемуі бойынша (басында бос)",
  "component.table-viewer.descendingEmptyEnd": "Кемуі бойынша (соңында бос)",
  "component.table-viewer.filter": "Сүзгі",
  "component.table-viewer.tune": "Баптау",
  "component.table-viewer.clearFilter": "Тазалау",
  "component.table-viewer.clearAll": "Барлығын тазалау",

  // table-output-settings
  "component.table-output-settings.outputFormat": "Шығару форматы",

  // onboarding-editor
  "component.onboarding-editor.name": "Аты",
  "component.onboarding-editor.label": "Шетке",
  "component.onboarding-editor.description": "Сипаттама",
  "component.onboarding-editor.attributes": "Атрибуттар",
  "component.onboarding-editor.actions": "Әрекеттер",
  "component.onboarding-editor.edit": "Өзгерту",
  "component.onboarding-editor.delete": "Жою",
  "component.onboarding-editor.enabled": "Қосылды",
  "component.onboarding-editor.disabled": "Өшірілді",
  "component.onboarding-editor.tour": "Тур",
  "component.onboarding-editor.add": "Қосу",
  "component.onboarding-editor.invalidName": "Жарамсыз аты",
  "component.onboarding-editor.totalItems": "Жалпы {i} элементтер",

  // table/conditions
  "component.table-conditions.noVar": "Айнымалы жоқ",
  "component.table-conditions.wrongVarType":
    "Айнымалы түрі өріс түріне сәйкес емес",
  "component.table-conditions.wrongConstType":
    "Тұрақты түрі өріс түріне сәйкес келмейді",
  "component.table-conditions.leftBorder": "Сол жақ шекарасы",
  "component.table-conditions.rightBorder": " Оң жақ шекарасы",

  // onboarding-step-editor
  "component.onboarding-step-editor.title": "Тақырып",
  "component.onboarding-step-editor.comment": "Пікір",
  "component.onboarding-step-editor.add": "Қосу",
  "component.onboarding-step-editor.edit": "Өзгерту",

  // base-modal-window
  "component.base-modal-window.cancel": "Болдырмау",

  // table-args
  "component.table-args.totalItems": "Жалпы {i} элементтер",

  // complex-inserter
  "component.complex-inserter.add": "Күрделі қосу",
  "component.complex-inserter.edit": "Күрделі өңдеу",
  "component.complex-inserter.delete": "Күрделі жою",

  ...fileManager,
  ...logger,
  ...loggerViewerLocaleRu
};
